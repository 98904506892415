<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col>
        <h1>Portal Users</h1>
        <b-button v-if="$root.$data.me.PortalAdmin" variant="primary" :to="{ name: 'portal-user-detail', params: { id: 'new' } }">
          Create new user
        </b-button>
      </b-col>

      <b-col cols="4" class="text-right">
        <label for="text-search" class="mr-4">Search</label>
        <b-form-input id="text-search" class="inline mr-4" v-model="filter.q" debounce="800" />
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <b-overlay :show="hideTable" rounded="sm">
          <rest-table
              endpoint="portal-users"
              :fields="fields"
              :filter="filter">

            <template v-slot:cell(Username)="data">
              <router-link :to="{ name: 'portal-user-detail', params: { id: data.item.PortalUserID } }">
                <strong>{{ data.item.Username }}</strong>
              </router-link>
            </template>

            <template v-slot:cell(LastLogin)="data">
              {{ data.item.LastLogin|dateFormat }}
            </template>

            <template v-slot:cell(Provider)="data">
              {{ data.item.Provider.Description }}
            </template>

            <template v-slot:cell(PortalAdmin)="data">
              <span v-if="data.item.PortalAdmin">Yes</span>
              <span v-if="!data.item.PortalAdmin">No</span>
            </template>

            <template v-slot:cell(actions)="data">
              <b-button-group>
                <b-button 
                  v-if="$root.$data.portalInfo.code != 'thso'" 
                  :to="{ name: 'portal-user-detail', params: { id: data.item.PortalUserID } }"
                >
                  <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                </b-button>

                <b-button
                  v-if="data.item.Active === 1 && $root.$data.me.IsSuperUser" 
                  @click="impersonate(data.item.PortalUserID)"
                  title="Impersonate"
                >
                  <b-icon icon="person-badge" aria-hidden="true"></b-icon>
                </b-button>
              </b-button-group>
            </template>
          </rest-table>
        </b-overlay>
      </b-col>
    </b-row>  
  </b-container>
</template>

<script>
  export default {
    name: 'PortalUsers',
    components: {
      'rest-table': () => import('@/components/RestTable.vue'),
    },
    data () {
      return {
        filter: { q: '' },
        hideTable: false,
      }
    },
    methods: {
      impersonate (userId) {
        this.hideTable = true

        if (this.$root.$data.me.IsSuperUser !== 1) {
          this.status = 'Access denied'
        }

        this.status = 'Getting token for user'

        this.$http.get('portal-users/' + userId + '/impersonate-token')
          .then(response => {
            const data = response.data

            let url = 'https://' + data.user.Provider.PortalHostName
            if (process.env.NODE_ENV !== "production") {
              url = url + '.test'
            }
            if (location.port != 443) {
              url = url + ':' + location.port
            }
            url = url + '/login#temp-access-token=' + data['temp-access-token']

            window.open(url)

            this.hideTable = false
          })
          .catch(() => {
            this.hideTable = false
            this.$notify({
              type: 'danger',
              text: 'An error occurred while impersonating, please try again'
            });
          })
      }
    },
    computed: {
      fields () {
        let out = [
          {
            key: 'Username',
            label: 'Username',
            sortable: true,
          },
          {
            key: 'FullName',
            label: 'Full name',
            sortable: true,
          },
          {
            key: 'EMail',
            label: 'E-mail',
            sortable: true,
          },
          {
            key: 'PortalAdmin',
            label: 'Admin',
            sortable: true,
          },
          {
            key: 'LastLogin',
            label: 'Last login',
            sortable: true,
          },
          {
            key: 'actions',
            label: 'Actions',
            class: 'link-icons',
          },
        ]

        if (this.$root.$data.me.IsSuperUser && !this.$root.$data.me.Provider) {
          out.unshift({
            key: 'Provider',
            label: 'Provider',
            sortable: false,
          })
        }
        
        return out
      },
    },
    mounted () {
    }
  }
</script>

<style scoped>
  label {
    font-weight: bold;
  }

  v-select {
    height: calc(1.5em + 0.75rem + 2px);
  }

  .inline {
    width: auto;
    display: inline-block;
  }
</style>